/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { QUESTION_IDS, BREAKPOINT_MAP } from 'consts';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { getImageDataFromWpResponse } from 'utils/transformHelpers';

/**
 * @summary this is used to create a map for all dependencies on a single question
 */
export function parseDependency(dependency) {
  if (!dependency || !dependency.length) {
    return {};
  }

  return dependency.reduce(
    (dependencyMap, { type, questionIds = [], validAnswers = [] }) => {
      // eslint-disable-next-line no-param-reassign
      dependencyMap[type] = {
        /*
         * note that dependencies come from question bank, so it could be that a question
         * is not on the current form, so we filter it out
         */
        questionIds: questionIds.map((id) => parseInt(id, 10)),
        validAnswers: validAnswers.length
          ? validAnswers.reduce(
              (
                dependantQuestionMap,
                { answers, questionId, shouldShowAsDefault }
              ) => {
                const validDependantAnswers = [...answers];

                if (shouldShowAsDefault) {
                  validDependantAnswers.push(null);
                }

                // eslint-disable-next-line no-param-reassign
                dependantQuestionMap[questionId] = validDependantAnswers;
                return dependantQuestionMap;
              },
              {}
            )
          : {},
      };

      return dependencyMap;
    },
    {}
  );
}

export function getAdditionalProperties(question) {
  const propertyMap = {};

  Object.values(BREAKPOINT_MAP).forEach((width) => {
    propertyMap[width.label] = {};
  });

  if (isEmpty(question.additionalProperties)) {
    return propertyMap;
  }

  Object.keys(question.additionalProperties).forEach((propName) => {
    const { screenWidth, ...currentProp } =
      question.additionalProperties[propName];
    let _screenWidths = screenWidth || [];

    // if no screenwidths are set, add the property to all screenwidths
    if (!_screenWidths.length) {
      _screenWidths = Object.keys(propertyMap);
    }

    // if screenwidths are set, add them to the appropriate map property
    _screenWidths.forEach((width) => {
      propertyMap[width] = {
        ...propertyMap[width],
        [propName]: { ...currentProp },
      };
    });
  });

  return propertyMap;
}

/**
 * @summary Backend sends groupings at the question level, the UI will see this as a new step with the same step counter
 */
export function parseStep(step, stepIndex, questionBankMap, groupAttributes) {
  const groupings = {};

  const stepInfo = {
    id: step.stepId,
    trueStepIndex: stepIndex,
    title: step.title,
    subTitle: step.subTitle,
    heading: step.heading,
  };

  // create a map of groupings to questions
  step.questions.forEach((question) => {
    groupings[question.grouping] = groupings[question.grouping] || [];

    groupings[question.grouping].push({
      ...questionBankMap[question.id],
      ...question,
      dependency: parseDependency(
        question.dependencyOverrides
          ? [question.dependencyOverrides]
          : get(questionBankMap[question.id], 'dependency')
      ),
      additionalProperties: getAdditionalProperties(question),
    });
  });

  return Object.keys(groupings).map((groupNum, index) => ({
    ...stepInfo,
    questions: groupings[groupNum],
    progressMeterRange: step.progressMeter,
    subStepIndex: index,
    groupLabel: get(groupAttributes, `[${groupNum}]groupLabel`, ''),
  }));
}

export function getSidebarContent(sidebarContent) {
  const _sidebarContent = {};

  if (sidebarContent.content) {
    _sidebarContent.content = sidebarContent.content;
  }

  if (sidebarContent.sidebarImage) {
    _sidebarContent.image = getImageDataFromWpResponse({
      image: sidebarContent.sidebarImage,
    });
  }

  return _sidebarContent;
}

// TODO: TALK ABOUT GETTING THIS INTO THE API. IT CAN RETURN A PROPS KEY THAT WE CAN SPREAD INTO THE COMPONENT
export const STATIC_PROPS = {
  [QUESTION_IDS.CONCENTRATIONS]: (fieldProps) => {
    const undecidedGroup = get(fieldProps, 'options', []).find(
      ({ group }) => group === 'Undecided'
    );
    const resetValue =
      get(undecidedGroup, 'options[0]') ||
      get(fieldProps, 'options', []).find(({ value }) => value === 'Undecided');
    return {
      resetValue,
    };
  },
};
