/* eslint-disable camelcase */
import { FIELD_TYPES } from 'components/AVAILABLE_COMPONENTS';
import { DEFAULT_SELECT_VALUE, FIELD_NAMES, QUESTION_IDS } from 'consts';
import get from 'lodash/get';
import { BasicFormWizardContext, GooglePlace } from 'types';
import { getFieldValue } from 'utils/formValuesUtils';
import { LogError } from 'utils/logging';

const {
  PRIMARY_PHONE_TYPE,
  SECONDARY_PHONE_TYPE,
  PRIMARY_PHONE,
  SECONDARY_PHONE,
} = FIELD_NAMES;

interface FormValueArrayEntry {
  status?: string;
  questionId: QUESTION_IDS;
  questionAnswer: string[];
}

function phoneNumberTransform(
  phoneNumber: string,
  formValues: BasicFormWizardContext['formValues']
): string[] {
  return [
    phoneNumber,
    getFieldValue(formValues, PRIMARY_PHONE_TYPE),
    getFieldValue(formValues, SECONDARY_PHONE),
    getFieldValue(formValues, SECONDARY_PHONE_TYPE),
  ].filter((val) => val);
}

/**
 * @summary use this to generate the request object for backend
 */
export function formValuesToRequestArr(
  formValues: BasicFormWizardContext['formValues'],
  fieldNameMap: BasicFormWizardContext['fieldNameMap'],
  excludedPii?: boolean,
  linkedSessionFormValues = {}
): FormValueArrayEntry[] {
  const replies = Object.keys(formValues).map((fieldName) => {
    const _fieldName = fieldName as FIELD_NAMES;
    const question = fieldNameMap[_fieldName];
    let answer = getFieldValue(formValues, _fieldName);

    if (!question || (question.isPii && excludedPii)) {
      return {};
    }

    if (fieldName === PRIMARY_PHONE) {
      answer = phoneNumberTransform(answer as string, formValues);
    } else if (
      typeof formValues[_fieldName] !== 'string' &&
      !Array.isArray(formValues[_fieldName]) &&
      typeof formValues[_fieldName] !== 'boolean' &&
      formValues[_fieldName]?.valueToSubmit
    ) {
      // it's possible we don't want to send a field's value attribute
      answer = formValues[_fieldName].valueToSubmit;
    } else if (question.type === FIELD_TYPES.GOOGLE_ADDRESS_LOOKUP) {
      if (!answer) {
        LogError('Address lookup field has no value');
        return {};
      }

      answer = [(answer as GooglePlace).formattedAddress];
    }

    let status;

    if (get(linkedSessionFormValues, `[${fieldName}].isFromOtherSite`)) {
      status = 'shared';
    } else if (get(formValues, `[${fieldName}].shouldSkip`)) {
      status = 'single';
    }

    return {
      status,
      questionId: question.id,
      // All backend questionAnswer must be an array, they can not accept strings
      questionAnswer: Array.isArray(answer) ? answer : [answer],
    };
  });

  return replies.filter(({ questionAnswer, questionId }) => {
    if (!questionId || !questionAnswer) {
      return false;
    }
    return !questionAnswer.includes(DEFAULT_SELECT_VALUE.value);
  }) as FormValueArrayEntry[];
}
