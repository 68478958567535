import { IS_DEBUG_ENABLED } from 'app-requests/apiConstants';
import { QUESTION_IDS, REGION_COOKIE_NAME } from 'consts';
import { LogError, LogInfo } from './logging';

const CF_HEADERS_NAME_MAP = {
  'cf-ipcity': 'cfCity',
  'cf-ipcountry': 'cfCountry',
  'cf-ipcontinent': 'cfContinent',
  'cf-iplongitude': 'cfLongitude',
  'cf-iplatitude': 'cfLatitude',
  'cf-region': 'cfRegion',
  'x-region': 'cfRegion', // from service worker
  'cf-region-code': 'cfRegionCode',
  'x-region-code': 'cfRegionCode', // from service worker
  'cf-metro-code': 'cfMetroCode',
  'cf-postal-code': 'cfPostalCode',
  'x-postal-code': 'cfPostalCode', // from service worker
  'cf-timezone': 'cfTimezone',
};

/**
 * @summary just a NOP function
 */
export function NOP() {
  return {};
}

/**
 * @summary trigger this to make the browser scroll to a given position
 */
export function scrollTo(top = 0) {
  // don't scroll if we're already at the right position
  if (window && window.scrollY === top) {
    return;
  }
  window.requestAnimationFrame(() =>
    window.scrollTo({
      top,
      left: 0,
      behavior: 'smooth',
    })
  );
}

/**
 * @summary call this to know if we are on click portal
 * @param {Object} req - normal request object
 * @returns boolean
 */
export function isClickPortal(req) {
  if (req) {
    return req.headers.host && req.headers.host.includes('highestgrades');
  }

  if (typeof window !== 'undefined') {
    return window.location.hostname.includes('highestgrades');
  }

  return false;
}

/**
 * @summary call this to know if we can cache the call to questionOptions
 * @param {Array} requestedOptions
 */
export function isQuestionOptionsCacheable(requestedOptions = []) {
  // IF YOU EVER UPDATE THIS WE WILL NEED TO UDPATE THE CACHE KEYS.
  return (
    requestedOptions.length === 1 &&
    requestedOptions[0] === QUESTION_IDS.SUBJECT_0F_INTEREST
  );
}

/**
 * @param {Function} thenableFunction - function to keep trying until it passes
 * @param {Number} options.maxRetryAttempts - thenableFunction function will be run this many time
 * @param {Number} options.waitTimeBetweenFails - on each fail we will wait this many milliseconds
 */
export function retryablePromise(thenableFunction, options = {}) {
  const { maxRetryAttempts = 3, waitTimeBetweenFails = 1000 } = options;

  function retryablePromise_(thenableFunction_, currentAttempts) {
    const isFinalAttempt = currentAttempts >= maxRetryAttempts;
    return thenableFunction_(isFinalAttempt).catch((error) => {
      LogInfo('RetryablePromise promise failed', {
        description: error.message,
      });
      if (currentAttempts < maxRetryAttempts) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(retryablePromise_(thenableFunction_, currentAttempts + 1));
          }, waitTimeBetweenFails);
        });
      }

      throw error;
    });
  }

  return retryablePromise_(thenableFunction, 1);
}

/**
 * @summary simple function that will pause execution of current function
 * @param {Number} waitTime milliseconds to wait
 */
export function wait(waitTime) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, waitTime);
  });
}

/**
 * @summary This function is used to figure out if we are able to use session Storage
 */
export const isSessionStorageEnabled = () => {
  try {
    const key = `storage_test`;
    const value = key;
    window.sessionStorage.setItem(key, value);
    const storedValue = window.sessionStorage.getItem(key);
    window.sessionStorage.removeItem(key);
    return storedValue === value;
  } catch (e) {
    return false;
  }
};

/**
 * @summary This function extracts the geolocation from the request headers
 */
export function getGeolocationFromRequest(request) {
  const geoLocation = {};

  Object.keys(CF_HEADERS_NAME_MAP).forEach((cloudFlareHeader) => {
    const headerValue = request.headers[cloudFlareHeader];
    const headerName = CF_HEADERS_NAME_MAP[cloudFlareHeader];
    geoLocation[headerName] = geoLocation[headerName] || headerValue;
  });

  if (!geoLocation.cfPostalCode || !geoLocation.cfRegionCode) {
    LogError('Postal/Region Code not found', {
      headers: request.headers,
      geoLocation,
    });

    console.log('postal code not found', {
      'cf-postal-code': request.headers['cf-postal-code'],
      'x-postal-code': request.headers['x-postal-code'],
      'X-Postal-Code': request.headers['X-Postal-Code'],
    });
  }

  return geoLocation;
}

export function handlerPersonalizedSearchRequest({ req }) {
  // Comes from cloudflare worker
  const personalizationHeader = req.headers['x-personalized-search-enabled'];
  // either from revisit or fallback Nextjs Page /my-search
  const personalizationCookie = req.cookies[REGION_COOKIE_NAME];
  const isPersonalized = Boolean(
    personalizationHeader || personalizationCookie
  );

  let geoLocation = {};

  if (isPersonalized) {
    geoLocation = getGeolocationFromRequest(req);
  }

  if (isPersonalized && !geoLocation.cfRegion) {
    LogError('GeoLocation not found', { geoLocation });
  }

  if (IS_DEBUG_ENABLED) {
    console.log('handlerPersonalizedSearchRequest: ', {
      personalizationHeader,
      personalizationCookie,
      isPersonalized,
      geoLocation,
    });
  }

  return { isPersonalized, geoLocation };
}
