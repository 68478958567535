/**
 * @module Logging
 * @summary The key function to log errors and key events to our dashboard will be here
 * @see LogError
 * @see LogInfo
 */
import { TrackJS } from './trackjs-isomorphic';
import isBrowser from './isBrowser';
import { getNewRelicAgent } from './logging/getNewRelicAgent';

const TRIGGERED_ERROR_MAP = {};
const IS_DEBUG_ENABLED = false;

/**
 * @summary Set the user ID for tracking
 * @param {Object} sessionData - sessionId, schoolCode, googleTagManagerSiteId, floodLightActivityFilters, isPersonalized, geoLocation, developmentEnvironment
 */
let sessionDataForLogging = {};

/**
 * @summary Will return true if the error has been triggered already on the user's session.
 *          If not it will keep track of this new error.
 * @param {String | Error} error - The error we are looking to record
 */
function _hasAlreadyLoggedThisSession(error) {
  try {
    const message = error.toString();
    if (!isBrowser()) {
      return false;
    }

    if (TRIGGERED_ERROR_MAP[message]) {
      return true;
    }

    TRIGGERED_ERROR_MAP[message] = true;
    return false;
  } catch (err) {
    return false;
  }
}

/**
 * Logs a message to New Relic with the specified level and custom attributes.
 *
 * @param {string} message - The message to log.
 * @param {string} level - The log level.
 * @param {object} meta - Custom attributes to include with the log message.
 * @return {void}
 */
function newRelicLogger(message, level, meta = {}) {
  const metaData = {
    description: meta.description,
    endpoint: meta.endpoint,
    'tms.app': 'microsite-platform',
    'entity.id': sessionDataForLogging.sessionId,
    environment: sessionDataForLogging.developmentEnvironment,
    schoolCode: sessionDataForLogging.schoolCode,
    sid: sessionDataForLogging.sid,
    siteType: sessionDataForLogging.siteType,
    applicationBuildId: sessionDataForLogging.environmentVariables?.buildId,
    triadBackendDomain:
      sessionDataForLogging.environmentVariables?.triadBackendDomain,
  };

  if (isBrowser()) {
    getNewRelicAgent().then((agent) => {
      if (agent && agent.log) {
        agent.log(message, {
          level,
          customAttributes: metaData,
        });
      }
    });
  }

  if (!isBrowser()) {
    // TODO [FSIU-65]: Add support for server logging to new relic
  }
}

/**
 * @summary The main error tracking function
 * @param {String | Error} error - The error we are looking to record
 */
export function LogError(error, meta = {}) {
  if (_hasAlreadyLoggedThisSession(error)) {
    return;
  }

  getNewRelicAgent().then((agent) => {
    if (agent && agent.noticeError) {
      agent.noticeError(error, meta);
      newRelicLogger(
        typeof error === 'string' ? error : error.message,
        'error',
        meta
      );
    }
  });

  if (TrackJS.isInstalled()) {
    Object.keys(meta).forEach((key) => {
      TrackJS.addMetadata(key, meta[key]);
    });

    TrackJS.track(error);

    // Removing as to not let meta values bleed into other errors
    Object.keys(meta).forEach((key) => {
      TrackJS.removeMetadata(key);
    });
  }
}

/**
 * @summary Get the session data used for logging
 * @returns {floodLightActivityFilters: {
 *   trackingSchoolCode: string,
 *   propertyOrigin: string,
 *   isClickUser: boolean,
 *   fvCampaignType: string,
 *   adPlatformSource: string
 * }}
 */
// TODO: [FSIU-143] This has gotten too hacky. We need to refactor this to be more manageable.
// All logging and analytics functions need to be in some module that has access to global context.
export function getSessionDataForLogging() {
  return sessionDataForLogging;
}

export function configureSessionForLogging(sessionData = {}) {
  if (sessionData.sessionId && TrackJS.isInstalled()) {
    if (isBrowser()) {
      TrackJS.addMetadata('sessionId', sessionData.sessionId);
    } else {
      TrackJS.addLogTelemetry(
        'info',
        `addMeta ran sessionId=${sessionData.sessionId}`,
        {
          sessionId: sessionData.sessionId,
        }
      );
    }
  }

  getNewRelicAgent().then((agent) => {
    if (agent && agent.setUserId && sessionData.sessionId) {
      agent.setUserId(sessionData.sessionId);
    }
    Object.keys(sessionData).forEach((key) => {
      if (agent && agent.setCustomAttribute) {
        agent.setCustomAttribute(key, sessionData[key]);
      }
    });
  });

  sessionDataForLogging = { ...sessionDataForLogging, ...sessionData };
}

/**
 * @summary Track a page view
 * @deprecated use Log Function instead
 * @param {Object} attributes - The attributes to track with the page view
 * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/using-browser-apis/
 */
export function newrelicPageAction(actionName, attributes = {}) {
  try {
    let gtmDataLayer;

    try {
      gtmDataLayer =
        window.google_tag_manager[sessionDataForLogging.googleTagManagerSiteId]
          .dataLayer;
    } catch (error) {
      gtmDataLayer = { get: () => {} };
    }

    const filterValues = sessionDataForLogging.floodLightActivityFilters || {};

    const globalAttributes = {
      ...attributes,
      gtmOriginSchoolCode: gtmDataLayer.get('origin_school_code'),
      fvTrackingSchoolCode: filterValues.trackingSchoolCode,
      fvPropertyOrigin: filterValues.propertyOrigin,
      fvIsClickUser: filterValues.isClickUser,
      fvCampaignType: filterValues.campaignType,
      fvAdPlatformSource: filterValues.adPlatformSource,
      schoolCode: sessionDataForLogging.schoolCode,
      developmentEnvironment: sessionDataForLogging.developmentEnvironment,
      gclid: sessionDataForLogging.searchEngineCookies?.gclid,
      msclkid: sessionDataForLogging.searchEngineCookies?.msclkid,
      sid: sessionDataForLogging.sid,
    };
    getNewRelicAgent().then((agent) => {
      if (agent && agent.addPageAction) {
        agent.addPageAction(actionName, globalAttributes);
      }
    });
  } catch (error) {
    console.error('newrelicPageAction', error);
  }
}

/**
 * Logs a warning message with custom attributes.
 *
 * @param {string} message - The warning message to log.
 * @param {object} meta - Custom attributes to include with the log message.
 * @return {void}
 */
export function LogWarning(message, meta = {}) {
  newRelicLogger(message, 'warn', meta);
}

/**
 * Logs a debug message with custom attributes.
 *
 * @param {string} message - The debug message to log.
 * @param {object} meta - Custom attributes to include with the log message.
 * @return {void}
 */
export function LogDebug(message, meta = {}) {
  if (IS_DEBUG_ENABLED) {
    newRelicLogger(message, 'debug', meta);
  }
}

/**
 * @summary This is only to be used for logging information, such as key events.
 * @param {String} message - The message to record
 * @param {Object} meta - extra key value pairs to record with this log
 */
export function LogInfo(message, meta = {}) {
  newRelicLogger(message, 'info', meta);
  if (TrackJS.isInstalled()) {
    if (isBrowser()) {
      window.console.info(`${message}. metaData=${JSON.stringify(meta)}`);
    } else {
      TrackJS.addLogTelemetry('info', message, meta);
    }
  }
}
