import { GrowthBookAPI } from 'types';
import isBrowser from 'utils/isBrowser';

const POLLING_INTERVAL_MS = 100;
const MAX_TIMEOUT_MS = 3000;

/**
 * Creates a GrowthBookAPI instance with the current window state
 * @returns GrowthBookAPI instance with current window configuration
 */
function createABTestingAgent(): GrowthBookAPI {
  if (!isBrowser()) {
    throw new Error('Cannot create AB testing agent on server side');
  }

  return {
    isABTestingEnabled: window.isABTestingEnabled ?? false,
    getFeatureValue: (featureFlag) =>
      window.triadGrowthBookAPI?.getFeatureValue?.(featureFlag) ?? '',
  };
}

/**
 * Waits for GrowthBook API to be available in the window object
 * @returns Promise that resolves with GrowthBookAPI when available
 * @throws Error if timeout occurs or running server-side
 */
export async function getABTestingAPI(): Promise<GrowthBookAPI> {
  // Early return if not in browser
  if (!isBrowser()) {
    throw new Error('AB Testing is not supported server-side');
  }

  // Return immediately if AB testing is disabled or API already exists
  if (!window.isABTestingEnabled || window.triadGrowthBookAPI) {
    return createABTestingAgent();
  }

  // Poll for GrowthBook API availability
  return new Promise((resolve, reject) => {
    let elapsedTime = 0;

    const checkForGrowthBook = setInterval(() => {
      if (window.triadGrowthBookAPI) {
        clearInterval(checkForGrowthBook);
        resolve(createABTestingAgent());
        return;
      }

      elapsedTime += POLLING_INTERVAL_MS;
      if (elapsedTime >= MAX_TIMEOUT_MS) {
        clearInterval(checkForGrowthBook);
        reject(new Error('Timeout waiting for GrowthBook API initialization'));
      }
    }, POLLING_INTERVAL_MS);
  });
}
