import { initialState } from 'hooks/reducers/GlobalReducer';
import React from 'react';
import { GlobalContextType } from 'types';

const globalContext: GlobalContextType = {
  // All Available Taxonomy Values for the site
  microSiteTaxonomyMap: null,
  sessionInfo: initialState.sessionInfo,
  questionnaire: {
    schoolCode: '',
    variant: '',
    id: 0,
    stepsCount: 0,
    steps: [],
    useLandingPageHeadline: false,
    isFullPage: false,
    hasDynamicDisclaimer: false,
  },
  // Page Level Taxonomy Values
  taxonomyValues: {
    degrees: [],
    parentCategories: [],
    categories: [],
  },
  flags: initialState.flags,
  userData: initialState.userData,
  refs: {
    heroRef: null,
    headerRef: null,
    modalContainerRef: null,
    clickPortalSearchResultsRef: null,
    themeWrapperRef: null,
  },
  resumeModal: {
    isOpen: false,
  },
  actions: {
    updateGlobalFlags: () => null,
    setActiveModalId: () => null,
    updateUserData: () => null,
    updateMicroPortalData: () => null,
    updateGlobalRefs: () => null,
  },
  windowSize: initialState.windowSize,
  clickPortal: initialState.clickPortal,
  formSettings: initialState.formSettings,
  microPortal: initialState.microPortal,
  siteMeta: {
    disclaimerSuccessText: '',
    floodlightActivityValues: {
      eventValues: [],
    },
    sharedDisclaimerOptInText: '',
    disclaimerText: '',
    schoolCode: '',
    siteName: '',
    siteType: '',
  },
};

const GlobalContext = React.createContext(globalContext);

export default GlobalContext;
