import request from 'utils/request';
import { FEATURE_FLAGS, Questionnaire, TriadmsResponse } from 'types';
import { LogError } from 'utils/logging';
import { getABTestingAPI } from './getABTestingAgent';

/**
 * Fetches an alternative questionnaire variant if A/B testing is enabled for the given school
 */
export async function getActiveTestingVariant(
  schoolCode: string
): Promise<Questionnaire | null> {
  try {
    const abTestingClient = await getABTestingAPI();
    const { isABTestingEnabled, getFeatureValue } = abTestingClient;

    // Early return if A/B testing is not configured
    if (!isABTestingEnabled) {
      return null;
    }

    const questionnaireVariant = getFeatureValue(
      FEATURE_FLAGS.ACTIVE_TESTING_VARIANT
    );

    // Early return if no active variant is configured
    if (!questionnaireVariant) {
      return null;
    }

    // Fetch the variant questionnaire from the API
    const variantQuestionnaire: TriadmsResponse<Questionnaire> = await request({
      method: 'get',
      cacheKey: `variant-questionnaire-${schoolCode}-${questionnaireVariant}`,
      url: '/api/triadms/getFormVariant',
      query: {
        schoolCode,
        variant: questionnaireVariant,
      },
    });

    if (!variantQuestionnaire.success) {
      LogError(
        `Failed to fetch questionnaire variant for school ${schoolCode}: ${variantQuestionnaire.error}`
      );
      return null;
    }

    return variantQuestionnaire.data;
  } catch (error) {
    LogError(
      `Failed to fetch questionnaire variant for school ${schoolCode}: ${error}`
    );
    return null;
  }
}
