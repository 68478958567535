import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import { LogWarning } from 'utils/logging';
import request from 'utils/request';

export enum EventTypes {
  BillBoard = 'UserVisit.FormLinkClickThrough.BillBoard',
  Header = 'UserVisit.FormLinkClickThrough.Header',
  Hero = 'UserVisit.FormLinkClickThrough.Hero',
  ProgramSelection = 'UserVisit.FormLinkClickThrough.ProgramSelection',
  ProgramInfo = 'UserVisit.FormLinkClickThrough.ProgramInfo',
}

export async function logClickEvent(
  eventName: EventTypes,
  schoolCode: string
): Promise<void> {
  try {
    if (!Object.values(EventTypes).includes(eventName)) {
      LogWarning('logClickEvent: Invalid event name', {
        description: `Invalid event name: ${eventName}`,
      });
      return;
    }

    await request({
      method: 'post',
      url: `${TRIAD_PROXY_ROUTE}/logevent`,
      body: { schoolCode, eventName },
    });
  } catch (error) {
    LogWarning('Failed to log form link click through event', {
      description: `${eventName}: ${
        error instanceof Error ? error.message : String(error)
      }`,
    });
  }
}
