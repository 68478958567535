import {
  getCookie,
  getStoredQueryParams,
  getUserSessionId,
  setPageViewId,
  setStoredQueryParams,
  setUserSessionId,
} from 'utils/analyticsHelpers';
import { generateUserSessionId } from 'app-requests/triadmsRequests';
import {
  multiValueEnabledFields,
  FIELD_TYPES,
} from 'components/AVAILABLE_COMPONENTS';
import {
  appendDataLayerValue,
  setAnsweredQuestions,
} from './trackingFunctions';
import { LogDebug, LogError } from './logging';
import { getTrafficSource } from './getSearchEngine';

/**
 * Its possible a user sessions answer is a multi value question
 * @param {String|Array} questionAnswer
 * @param {FIELD_TYPES} questionType
 */
function transformPossibleMultiAnswer(questionAnswer, questionType) {
  /**
   * Never rehydrate disclaimer as it can be invalid when coming back from a user session
   * Although this is not really an error it can be inaccurate. We are not collecting PII
   * and the disclaimer is dependent on user phone number. Due to this we will never request
   * the options to know what schools should be listed, so we need a fresh list when a user
   * gets to the end of the form. Logging an error here to see how often this would happen.
   * Its unlikely it will because for this to be logged a user needs to submit the form.
   */
  if (questionType === FIELD_TYPES.DYNAMIC_DISCLAIMER) {
    return null;
  }

  if (typeof questionAnswer === 'string') {
    return questionAnswer;
  }

  if (Array.isArray(questionAnswer)) {
    if (multiValueEnabledFields.includes(questionType)) {
      return questionAnswer.map((value) => ({ value }));
    }

    return questionAnswer[0];
  }

  LogError('Unsupported value type for user session question', {
    type: typeof questionAnswer,
  });
  return questionAnswer;
}

/**
 * @summary this is used to derive the initial values for the form on the page given backend's session response
 * @param {Array} userAnswers - response from getUserProfile API
 * @param {Object} questionnaire - the current questionnaire on the page
 */
export function initSessionFormValues(userAnswers, questionnaire = {}) {
  // creates a map keyed by question id and its value is the questions configs
  const allQuestions = questionnaire.steps
    .map(({ questions }) => questions)
    .flat()
    .reduce((map, question) => ({ ...map, [question.id]: question }), {});

  // we only care for the questions in this form, the user could have answered a different questionnaire
  const questionIdsInForm = Object.keys(allQuestions);
  const userValidAnswers = userAnswers.filter(({ questionId }) =>
    // note the == and not === as sometime the ids are strings or numbers and won't match up
    // eslint-disable-next-line eqeqeq
    questionIdsInForm.some((id) => id == questionId)
  );

  // create an object {[question.name]: value}
  const formValues = userValidAnswers.reduce(
    (map, { questionAnswer, questionId }) => ({
      ...map,
      [allQuestions[questionId].name]: transformPossibleMultiAnswer(
        questionAnswer,
        allQuestions[questionId].type
      ),
    }),
    {}
  );

  // formValues may come from another microsite
  const linkedSessionFormValues = userValidAnswers.reduce(
    (map, { questionId, status }) => ({
      ...map,
      [allQuestions[questionId].name]: {
        status,
        isFromOtherSite: status === 'skip',
      },
    }),
    {}
  );

  setAnsweredQuestions(Object.keys(formValues));
  return { linkedSessionFormValues, formValues };
}

/**
 * @summary this is called on page load for us to init any tracking stuff we need
 * @param {Object} queryParams - map of url query string params
 */
export function initializeSession(queryParams = {}, siteMeta = {}) {
  const userSessionId = getUserSessionId();
  const { taxonomyValues } = siteMeta;
  const { parentCategories, categories, degrees, programs } =
    taxonomyValues || {};

  setStoredQueryParams(queryParams);
  const { queryParamMap } = getStoredQueryParams();

  appendDataLayerValue('search_engine', getTrafficSource());
  appendDataLayerValue('taxonomy_parent_category', parentCategories?.join('|'));
  appendDataLayerValue('taxonomy_category', categories?.join('|'));
  appendDataLayerValue('taxonomy_degree', degrees?.join('|'));
  appendDataLayerValue('taxonomy_programs', programs?.join('|'));

  return generateUserSessionId(queryParamMap, siteMeta).then(
    ({ sessionId, pageViewId, floodLightActivityFilters }) => {
      if (!userSessionId) {
        setUserSessionId(sessionId);
      } else {
        LogDebug('initializeSession', {
          description: 'userSessionId found, not setting sessionId',
        });
      }

      setPageViewId(pageViewId);

      // Set for GA tracking
      appendDataLayerValue('user_visit_id', pageViewId);
      appendDataLayerValue('sessionID', sessionId);
      appendDataLayerValue('gclid', getCookie('_ga'));
      appendDataLayerValue('ga_session_id', getCookie('_ga'));
      queryParamMap.gclid &&
        appendDataLayerValue('gclid_value', queryParamMap.gclid);

      return {
        sessionId: userSessionId || sessionId,
        pageViewId,
        floodLightActivityFilters,
      };
    }
  );
}
