/**
 * @summary this function can be run on the browser and on the server to let us know for sure what the current env is
 * @returns Boolean
 */
export default function isDevelopment() {
  // this will be available both on browser and server
  return (
    process.env.NEXT_PUBLIC_CONFIG_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  );
}
