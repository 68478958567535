import { FIELD_TYPES } from 'components/AVAILABLE_COMPONENTS';
import {
  parseStep,
  getSidebarContent,
} from 'components/form-wizards/basic-form/triadToPropsTransform';
import { DEPENDENCIES } from 'consts';
import get from 'lodash/get';
import {
  GetQuestionnaireArgs,
  Questionnaire,
  Step,
  WordpressQuestionnaire,
} from 'types';
import { LogError } from 'utils/logging';

export function questionnairePropsTransform(
  questionnaire: WordpressQuestionnaire,
  {
    questionBankMap,
    schoolCode,
    variant,
  }: {
    questionBankMap: GetQuestionnaireArgs['questionBankMap'];
    schoolCode: GetQuestionnaireArgs['schoolCode'];
    variant: GetQuestionnaireArgs['variant'];
  }
): Questionnaire {
  const _questionnaire = {
    schoolCode,
    variant,
    hasDynamicDisclaimer: false,
    component: questionnaire.component || 'DEFAULT_FORM',
    mobileComponent: questionnaire.mobileComponent || 'DEFAULT_FORM',
    showSidebar: get(questionnaire, ['displayOptions', 'showSidebar'], false),
    showSteps: get(questionnaire, ['displayOptions', 'showSteps'], false),
    sidebarContent: getSidebarContent(
      get(questionnaire, ['sidebarContent'], '')
    ),
    successRedirectUrl: questionnaire.successRedirectUrl || '',
    id: questionnaire.questionnaireId,
    subTitle: questionnaire.subTitle,
    title: questionnaire.title,
    stepsCount: questionnaire.steps.length,
    // note the length of this array is, steps * the groups in each step
    steps: questionnaire.steps
      .map((step, index) =>
        parseStep(step, index, questionBankMap, questionnaire.groupAttributes)
      )
      .flat() as Step[],
  };

  /**
   * We need to do this post processing because we do not know
   * the true order of the steps until the whole questionnaire has
   * been parsed and we flattened out the Steps vs Grouping order
   */
  const availableQuestionIds = new Set();
  // eslint-disable-next-line no-restricted-syntax
  for (const step of _questionnaire.steps) {
    step.questions
      .map(({ id }) => id)
      .forEach((id) => availableQuestionIds.add(id));
    // eslint-disable-next-line no-restricted-syntax
    for (const question of step.questions) {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const dependency in question.dependency) {
        const questionDependency =
          question.dependency[dependency as DEPENDENCIES];
        if (questionDependency) {
          questionDependency.questionIds =
            questionDependency.questionIds.filter(
              (id) => availableQuestionIds.has(id) && question.id !== id
            );
        }
      }
    }
  }

  if (!questionnaire.steps.length || !_questionnaire.steps.length) {
    LogError('triadToPropsTransform: no question steps in questionnaire', {
      variant,
      schoolCode,
    });
  }

  // Its possible that we want to submit on a step that is not the last when a DYNAMIC_DISCLAIMER is present
  _questionnaire.hasDynamicDisclaimer = _questionnaire.steps.some((step) =>
    step.questions.some(
      (question) => question.type === FIELD_TYPES.DYNAMIC_DISCLAIMER
    )
  );

  return _questionnaire;
}
