import { UserTrackingFilters } from 'types';
import { getSessionDataForLogging } from 'utils/logging';
import { getSearchEngineCookies } from 'utils/getSearchEngine';

function getCommonDescription(revenue: string): string {
  const userTrackingInfo = getSessionDataForLogging();
  const floodLightFilters = userTrackingInfo.floodLightActivityFilters || {};

  const { gclid, msclkid } = getSearchEngineCookies();

  const userFilterString = `Tracking School Code: ${floodLightFilters.trackingSchoolCode}, Property Origin: ${floodLightFilters.propertyOrigin}, Is Click User: ${floodLightFilters.isClickUser}, Campaign Type: ${floodLightFilters.fvCampaignType}, Ad Platform Source: ${floodLightFilters.adPlatformSource}`;

  return `Revenue: ${revenue}, hasMsclkid: ${!!msclkid}, hasGclid: ${!!gclid}, ${userFilterString}`;
}

export function getGoogleDescription(
  google: UserTrackingFilters['google'],
  revenue: string
): string {
  const description = `Advertiser ID: ${google.advertiserId}, Activity Tag: ${google.activityTagString}, Group Tag: ${google.groupTagString}`;
  return `${description}, ${getCommonDescription(revenue)}`;
}

export function getMicrosoftDescription(
  microsoft: UserTrackingFilters['microsoft'],
  revenue: string
): string {
  const description = `Event Action: ${microsoft.action}, Event Category: ${microsoft.category}, Event Label: ${microsoft.label}, Event Value: ${microsoft.value}`;
  return `${description}, ${getCommonDescription(revenue)}`;
}
