import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import { QUERY_PARAMS, FIELD_NAMES } from 'consts';
import isNumber from 'lodash/isNumber';
import {
  appendAndEvalRawHtml,
  deleteCookie,
  getAllCookies,
  getStoredQueryParams,
  getTidCookieOrQueryParams,
  getUserSessionId,
} from './analyticsHelpers';
import {
  getSessionDataForLogging,
  LogError,
  LogInfo,
  newrelicPageAction,
} from './logging';
import { retryableRequest } from './request';
import { getUnansweredQuestions } from './formValuesUtils';
import {
  getGoogleDescription,
  getMicrosoftDescription,
} from './logging/getTrackingTagDebugDescription';

const EVENTS_MAP = {
  PAGE_VIEW: 'page_view',
  FORM_WIZARD_VIEW: 'form_wizard_view',
  FORM_WIZARD_STARTED: 'form_wizard_started',
  JF_FORM_WIZARD_STARTED: 'lead_start',
  QUESTION_VIEWED: 'question_viewed',
  QUESTION_ANSWERED: 'question_answered',
  FORM_SUBMIT: 'form_submit',
  JF_FORM_SUBMIT: 'lead_submit',
  FORM_MOVE_BACK: 'back_button_clicked',
  FORM_RESUME: 'resume_viewed',
  FORM_RESUME_SELECTION: 'resume_answered',
  AUDIENCE_GROUP: 'audience_group_assigned',
  PAGE_SECTION_VIEW: 'page_section_view',
  PROFILE_PAYABLE_USER: 'profile_payable_user',
  PROFILE_CREATED: 'profile_created',
  JF_PROFILE_CREATED: 'lead_midpoint',
  SECONDS_ON_PAGE: 'seconds_on_page',
  GENERIC_EVENT: 'ga_generic_event',
  USER_INITIAL_SCROLL: 'user_initial_scroll',
  CP_RESULTS_ACTION: 'school_listing_action',
  CP_CLICK_CONVERSION: 'click_portal_school_listing_click',
  CROSS_DOMAIN_CONVERSION: 'cross_domain_conversion',
  FLOODLIGHT_COUNT_EVENT: 'floodlight_counter_event',
  FLOODLIGHT_CONVERSION_EVENT: 'floodlight_conversion_event',
  MICROSOFT_PAGE_VIEW: 'microsoft_page_view',
  MICROSOFT_CONVERSION_EVENT: 'microsoft_conversion_event',
  SCHOOL_CARD_EXPANDED: 'school_card_expanded',
};

export const MODAL_INTERACTION_TYPES = {
  CLOSE: 0,
  ACCEPT: 1,
  CANCEL: 2,
};

const {
  PRIMARY_PHONE,
  PRIMARY_PHONE_TYPE,
  SECONDARY_PHONE,
  SECONDARY_PHONE_TYPE,
  EMAIL,
  FIRST_NAME,
  STATE,
  CITY,
  ZIP,
  LAST_NAME,
  STREET_ADDRESS,
} = FIELD_NAMES;

const localFlags = {
  isFormStarted: false,
  isProfileCreated: false,
};

const MICROSOFT = 'microsoft';
const GOOGLE = 'google';

function getCommonSessionData() {
  const {
    floodLightActivityFilters: {
      trackingSchoolCode,
      propertyOrigin,
      isClickUser,
      campaignType,
      adPlatformSource,
    },
    schoolCode,
    developmentEnvironment,
    sessionId,
    siteType,
  } = getSessionDataForLogging();

  return {
    school_code: schoolCode,
    click_user: isClickUser,
    sid: sessionId,
    session_id: sessionId,
    campaign_type: campaignType,
    ad_platform_source: adPlatformSource,
    property_origin: propertyOrigin,
    tracking_school_code: trackingSchoolCode,
    site_type: siteType,
    development_environment: developmentEnvironment,
  };
}

function gaEvent(eventObj) {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventObj);
  } catch (error) {
    LogError(`Failed to trigger GA tracking: ${error.message}`);
  }
}

async function sha256(str) {
  if (!str) return '';
  const msgBuffer = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}

async function extractUserInfo(formValues) {
  const userInfo = {};

  if (!formValues) {
    return userInfo;
  }

  try {
    const [
      sha256Email,
      sha256Phone,
      sha256FirstName,
      sha256LastName,
      sha256Address,
    ] = await Promise.all([
      sha256(formValues[EMAIL]),
      sha256(formValues[PRIMARY_PHONE]),
      sha256(formValues[FIRST_NAME]),
      sha256(formValues[LAST_NAME]),
      sha256(formValues[STREET_ADDRESS]),
    ]);

    if (sha256Email) {
      userInfo.sha_256_email = sha256Email;
    }

    if (sha256Phone) {
      userInfo.sha_256_phone = sha256Phone;
    }

    if (sha256FirstName) {
      userInfo.sha_256_first_name = sha256FirstName;
    }

    if (sha256LastName) {
      userInfo.sha_256_last_name = sha256LastName;
    }

    if (sha256Address) {
      userInfo.sha_256_address = sha256Address;
    }

    if (formValues[STATE]) {
      userInfo.user_state = formValues[STATE];
    }

    if (formValues[CITY]) {
      userInfo.user_city = formValues[CITY];
    }

    if (formValues[ZIP]) {
      userInfo.user_zip_code = formValues[ZIP];
    }
  } catch (error) {
    LogError(`Error extracting user info: ${error.message}`);
  }

  return userInfo;
}

/**
 * @summary use this function to add more data to the data layer so that we can send it to GA via Google Tag Manager Integration
 * @param {String} key
 * @param {String|Number|Boolean} value
 */
export function appendDataLayerValue(key, value) {
  if (typeof key !== 'string') {
    console.error('"key" must be a string');
  } else {
    gaEvent({ [key]: value });
  }
}

/**
 * @summary use this to get the pixel to fire for a given profileGuid aka Publisher Guid
 * @param {String} type - either landing or conversion
 * @param {String} pubCodeGuid - some guid
 */
export function getTriadPixel({ type, pubCodeGuid }) {
  return retryableRequest({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/conversionPixelInfo`,
    responseTimeout: 5000,
    deadlineTimeout: 5000,
    body: {
      publisherCodeGuid: pubCodeGuid,
      callType: type,
      sessionId: getUserSessionId(),
    },
  }).then(({ trackingCode }) => trackingCode);
}

/**
 * @summary the main function that will send data to all our report vendors
 */
export function trackPageView(customData = {}) {
  const { questionnaireVariant, siteName, schoolCode, googleTagManagerSiteId } =
    customData;

  // Send It To GA
  gaEvent({
    event: EVENTS_MAP.PAGE_VIEW,
    questionnaire_id: questionnaireVariant,
    site_name: siteName,
    school_code: schoolCode,
    gtm_container_id: googleTagManagerSiteId,
  });
}

/**
 * @summary main function to track conversions
 * @param {Number} revenue - value returned from the API
 * @param {Number} adjustedRevenue - adjusted value returned from the API
 * @param {String} profileGuid - profile guid
 */
export function trackGAConversion(revenue, adjustedRevenue, profileGuid) {
  // We get this from cookie because a user might navigate the site losing its value
  const { queryParamMap } = getStoredQueryParams();
  const pubCodeGuid = queryParamMap[QUERY_PARAMS.PUB_CODE_GUID];
  const originSchoolCode = queryParamMap[QUERY_PARAMS.ORIGIN_SCHOOL_CODE];

  if (!localFlags.isProfileCreated) {
    LogError(
      'Profile Creation: Profile Creation tag not fired but the form was submitted'
    );
  }

  newrelicPageAction(EVENTS_MAP.PROFILE_PAYABLE_USER);
  gaEvent({
    event: EVENTS_MAP.PROFILE_PAYABLE_USER,
    category: 'Profile',
    action: 'Payable User',
    payable_user_revenue: revenue,
    adjusted_payable_user_revenue: adjustedRevenue,
  });

  if (originSchoolCode) {
    gaEvent({
      event: EVENTS_MAP.CROSS_DOMAIN_CONVERSION,
      category: originSchoolCode,
      payable_user_revenue: revenue,
      adjusted_payable_user_revenue: adjustedRevenue,
    });
  }

  const tid = getTidCookieOrQueryParams();

  if (tid) {
    LogInfo('User with transaction id', {
      description: `tid: ${tid} pubCodeGuid: ${pubCodeGuid}`,
    });
  }

  if (pubCodeGuid) {
    // Note that if a user comes to the site with an SID then navigates away and come back they will still be associated with the last SID they came to the site with for their session. If they then convert we will clear their association with that SID
    getTriadPixel({
      pubCodeGuid,
      type: 'conversion',
    })
      .then((pixels) => appendAndEvalRawHtml(pixels, profileGuid))
      .then(() => deleteCookie(pubCodeGuid));
  }
}

/**
 * @summary trigger this each time a question is viewed
 * @param {Object} question - The question object from the form
 * @param {Object} formValues - current state of the form
 */
const _viewedQuestions = {};
export function trackQuestionViewed(question, formValues) {
  gaEvent({
    event: EVENTS_MAP.QUESTION_VIEWED,
    category: 'viewed',
    action: question.label,
    question_value: question.id,
    is_repeat:
      // _viewedQuestions is for page level where getUnansweredQuestions is for prefilled session form
      !!_viewedQuestions[question.name] ||
      !getUnansweredQuestions([question], formValues).length,
  });

  _viewedQuestions[question.name] = true;
}

/**
 * Used to set answeredQuestion so we can track for GA
 * @param {Array<String>} questions all questions that have been answered
 */
const _answeredQuestions = {};
export function setAnsweredQuestions(questions) {
  questions.forEach((questionName) => {
    _answeredQuestions[questionName] = true;
    _viewedQuestions[questionName] = true;
  });
}

/**
 * @summary trigger this each time a question is answered
 * @param {Array|String|Number|Boolean} answer - The users answer
 * @param {Object} question - The question object from the form
 * @param {Boolean} isStepBeingSkipped - if the current questions on the step are being skipped
 */
export function trackQuestionAnswer(
  answer,
  question,
  isStepBeingSkipped = false,
  formValues = {}
) {
  try {
    const isEmail = question.name === EMAIL;
    const _answer = Array.isArray(answer) ? answer : [answer];

    let trackingLabel = _answer
      .map((option) => `${option.label} - ${option.guid || option.value}`)
      .join('|');

    // answer could be string from input
    trackingLabel = ['string', 'number', 'boolean'].includes(typeof answer)
      ? answer
      : trackingLabel;

    // don't track PII
    trackingLabel = question.isPii ? '*PII*' : trackingLabel;

    let answerValue = ['string', 'number', 'boolean'].includes(typeof answer)
      ? answer
      : answer.guid || answer.value;

    answerValue = question.isPii ? '*PII*' : answerValue;

    gaEvent({
      event: EVENTS_MAP.QUESTION_ANSWERED,
      category: 'answered',
      action: question.label,
      label: trackingLabel,
      answer_value: answerValue,
      question_value: question.id,
      skipped: isStepBeingSkipped,
      is_repeat: !!_answeredQuestions[question.name],
    });

    if (isEmail) {
      localFlags.isProfileCreated = true;
      gaEvent({
        event: EVENTS_MAP.PROFILE_CREATED,
        category: 'Profile',
        action: 'Created',
      });

      const sessionData = getCommonSessionData();

      extractUserInfo(formValues).then((userInfo) => {
        gaEvent({
          event: EVENTS_MAP.JF_PROFILE_CREATED,
          lead: sessionData,
          user: userInfo,
        });
      });
    }
    setAnsweredQuestions([question.name]);
    LogInfo('Question Answered', {
      description: `question: ${question.name} answer: ${trackingLabel}`,
    });
  } catch (error) {
    // don't report failed log attempts
  }
}

/**
 * @summary we have special fields, like Phone, that need to be handled
 */
export function trackSpecialFieldTypes(formValues) {
  // T1-3345
  function trackAnswered(action) {
    gaEvent({
      action,
      event: EVENTS_MAP.QUESTION_ANSWERED,
      category: 'answered',
      label: 'PII',
    });
  }

  try {
    if (formValues[PRIMARY_PHONE_TYPE]) {
      trackAnswered(`Phone Type ${formValues[PRIMARY_PHONE_TYPE].label}`);
    }

    if (formValues[SECONDARY_PHONE]) {
      trackAnswered('Secondary Phone');
    }

    if (formValues[SECONDARY_PHONE_TYPE]) {
      trackAnswered(
        `Secondary Phone Type ${formValues[SECONDARY_PHONE_TYPE].label}`
      );
    }
  } catch (error) {
    // don't report failed log attempts
  }
}

/**
 * @summary trigger when form is submitted
 */
export async function trackFormSubmit() {
  gaEvent({
    event: EVENTS_MAP.FORM_SUBMIT,
    category: 'profile',
    action: 'create',
    label: getUserSessionId(),
  });
}

/**
 * @summary trigger when form is submitted
 */
export async function trackLeadSubmit(formValues, metadata = {}) {
  try {
    const sessionData = getCommonSessionData();

    const userInfo = await extractUserInfo(formValues);

    gaEvent({
      event: EVENTS_MAP.JF_FORM_SUBMIT,
      lead: { ...sessionData, ...metadata },
      user: userInfo,
    });
  } catch (error) {
    LogError(`Error tracking form submit ${error.message}`);
  }
}

/**
 * @summary trigger when a card is submitted
 */
export function trackMicroPortalCardSubmitted() {
  gaEvent({
    event: EVENTS_MAP.FORM_SUBMIT,
    action: 'create',
    category: 'profile',
    label: 'microportal',
  });
}

/**
 * @summary trigger this when a form wizard is loaded
 */
export function trackWizardView(schoolCode, questionnaireVariant) {
  gaEvent({
    event: EVENTS_MAP.FORM_WIZARD_VIEW,
    category: 'AB Test Variant Assigned',
    action: schoolCode,
    label: questionnaireVariant,
  });
}

/**
 * @summary trigger this when a user selects value from program search
 */
export function trackAudienceGroup(option) {
  gaEvent({
    event: EVENTS_MAP.AUDIENCE_GROUP,
    category: 'Audience Group Assigned',
    action: option.label,
    label: option.value,
  });
}

/**
 * @summary trigger this when a user selects value from program search
 */
export function trackUserMoveBack(questions = []) {
  if (!Array.isArray(questions) || !questions.length) {
    return;
  }
  gaEvent({
    event: EVENTS_MAP.FORM_MOVE_BACK,
    action: questions.map(({ id }) => id).join(','),
    label: questions.map(({ label }) => label).join(','),
  });
}

/**
 * @summary trigger this when a user scrolls into view of a page section
 */
export function trackSectionView(sectionName, gaTrackingTags) {
  gaEvent({
    event: EVENTS_MAP.GENERIC_EVENT,
    ga_event_name: EVENTS_MAP.PAGE_SECTION_VIEW,
    label: sectionName,
    ga_tracking_tag: gaTrackingTags,
  });
}

/**
 * @summary track when user click Request Info On Micro Portal School Card
 */
export function trackSchoolCardExpanded() {
  gaEvent({
    event: EVENTS_MAP.GENERIC_EVENT,
    ga_event_name: EVENTS_MAP.SCHOOL_CARD_EXPANDED,
  });
}

/**
 * @summary trigger a time on page event
 */
export function trackSecondsOnPage(seconds = 0) {
  setTimeout(
    () =>
      gaEvent({
        event: EVENTS_MAP.GENERIC_EVENT,
        ga_event_name: EVENTS_MAP.SECONDS_ON_PAGE,
        label: seconds,
      }),
    seconds * 1000
  );
}

/**
 * @summary trigger this when a user interacts with the resume modal
 */
export function trackResumeModal(args = {}) {
  const { isShow, interaction } = args;

  if (isShow) {
    gaEvent({
      event: EVENTS_MAP.FORM_RESUME,
    });
  } else if (isNumber(interaction)) {
    gaEvent({
      event: EVENTS_MAP.FORM_RESUME_SELECTION,
      action: interaction,
    });
  }
}

/**
 * @summary use this to track questions in GA
 * @param {Array} questions - all question we want to track
 * @param {Object} formValues - current state of the form
 * @param {Boolean} isStepBeingSkipped - if the current questions on the step are being skipped
 */
export function trackQuestionAnswersOnStep(
  questions,
  formValues,
  isStepBeingSkipped
) {
  questions.forEach((question) => {
    const userAnswer = formValues[question.name];
    if (userAnswer) {
      trackQuestionAnswer(userAnswer, question, isStepBeingSkipped, formValues);
    }

    if (question.name === PRIMARY_PHONE) {
      trackSpecialFieldTypes(formValues);
    }
  });
}

/**
 * @summary trigger this when a form wizard is started
 */
export function trackWizardStarted(schoolCode, questionnaireVariant) {
  if (!localFlags.isFormStarted) {
    localFlags.isFormStarted = true;

    gaEvent({
      event: EVENTS_MAP.FORM_WIZARD_STARTED,
      category: 'Wizard Start',
      action: schoolCode,
      label: questionnaireVariant,
    });

    const sessionData = getCommonSessionData();

    gaEvent({
      event: EVENTS_MAP.JF_FORM_WIZARD_STARTED,
      lead: {
        ...sessionData,
        questionnaire_variant: questionnaireVariant,
      },
    });

    LogInfo('Form Started', {
      description: `schoolCode: ${schoolCode} questionnaireVariant: ${questionnaireVariant}`,
    });
  }
}

/**
 * @summary trigger this when a user scrolls for the first time
 */
export function trackUserInitialScroll() {
  gaEvent({
    event: EVENTS_MAP.GENERIC_EVENT,
    ga_event_name: EVENTS_MAP.USER_INITIAL_SCROLL,
  });
}

/**
 * @summary Track a click portal listing view/click
 * @param {Boolean} isView - was the card viewed or clicked
 * @param {String} impressionGuid - an impression guid passes back for each listing result
 * @param {String} actionLocation - the location on the result card that this event was fired for
 * @see https://triadms.atlassian.net/wiki/spaces/NEWPLATFOR/pages/1887469569/Analytics+Requirements
 */
export function trackClickPortalListingAction(
  isView,
  impressionGuid,
  actionLocation
) {
  gaEvent({
    event: EVENTS_MAP.GENERIC_EVENT,
    ga_event_name: EVENTS_MAP.CP_RESULTS_ACTION,
    action: isView ? 'view' : 'click',
    impressionGuid,
    label: actionLocation,
  });
}

/**
 * @summary Track a click portal listing click
 * @param {number} revenue - revenue value passed by backend
 * @param {String} resultSchoolCode - school code for this listing
 */
export function trackClickPortalClickConversion(revenue, resultSchoolCode) {
  gaEvent({
    event: EVENTS_MAP.CP_CLICK_CONVERSION,
    cp_click_revenue: revenue,
    cp_click_school_code: resultSchoolCode,
  });
}

/**
 * @summary Track a click portal form submission
 * @param {Object} formValues - current form values
 */
export function trackClickPortalFormSubmit(formValues) {
  const trackingLabel = Object.entries(formValues)
    .map(([key, value]) => `${key}: ${value?.value}`)
    .join(' | ');

  gaEvent({
    event: EVENTS_MAP.FORM_SUBMIT,
    category: 'schools',
    action: 'search',
    label: `form value selection | ${trackingLabel}`,
  });
}

// TODO: [T1-11350] Rename from FLOODLIGHT_EVENTS to ATTRIBUTION_EVENTS
export const FLOODLIGHT_EVENTS = {
  PAGE_VIEW: EVENTS_MAP.PAGE_VIEW,
  FORM_WIZARD_STARTED: EVENTS_MAP.FORM_WIZARD_STARTED,
  PROFILE_CREATED: EVENTS_MAP.PROFILE_CREATED,
  PROFILE_PAYABLE_USER: EVENTS_MAP.PROFILE_PAYABLE_USER,
  TEST_EVENT: 'TEST_EVENT',
};

// TODO: [T1-11350] Rename from BACKEND_EVENT_TO_FLOODLIGHT_EVENT_MAP to BACKEND_EVENT_TO_ATTRIBUTION_EVENTS_EVENT_MAP.
const BACKEND_EVENT_TO_FLOODLIGHT_EVENT_MAP = {
  TEST_EVENT: ['TEST_EVENT'],
  [EVENTS_MAP.PAGE_VIEW]: ['Visit'],
  [EVENTS_MAP.FORM_WIZARD_STARTED]: ['Start_Wizard'],
  [EVENTS_MAP.PROFILE_CREATED]: ['Profile_Created'],
  [EVENTS_MAP.PROFILE_PAYABLE_USER]: [
    'Conversion_Original_Lead_Revenue',
    'Conversion_Adjusted_Lead_Revenue',
  ],
};

/**
 * @summary Get the GA session ids
 */
function getGaSessionIds() {
  let gaSessionIds = {};
  try {
    const cookies = getAllCookies();
    gaSessionIds = cookies.keys
      .filter((key) => key.match(/^(_ga|_gcl_)/))
      .reduce((keyValue, key) => {
        // eslint-disable-next-line no-param-reassign
        keyValue[key] = cookies.map[key];
        return keyValue;
      }, {});
  } catch (error) {
    // don't report
  }

  return gaSessionIds;
}

/**
 * @summary Track a floodlight conversion
 * @param {String} advertiserId - advertiser id
 * @param {String} activityTagString - activity tag string
 * @param {String} groupTagString - group tag string
 * @param {Object} metaData - { revenue }
 */
export async function trackFloodlightConversion(trackingInfo, formValues = {}) {
  const { google, revenue } = trackingInfo;

  const userInfo = await extractUserInfo(formValues);

  const gaEventData = {
    event: EVENTS_MAP.FLOODLIGHT_CONVERSION_EVENT,
    floodlight_activity_tag: google.activityTagString,
    floodlight_group_tag: google.groupTagString,
    floodlight_advertiser_id: google.advertiserId,
    floodlight_revenue: revenue,
    user: userInfo,
  };

  gaEvent(gaEventData);

  newrelicPageAction(EVENTS_MAP.FLOODLIGHT_CONVERSION_EVENT, {
    ...gaEventData,
    ...getGaSessionIds(),
  });
  LogInfo(EVENTS_MAP.FLOODLIGHT_CONVERSION_EVENT, {
    description: getGoogleDescription(google, revenue),
  });

  return gaEventData;
}

/**
 * @summary Track a floodlight counter
 * @param {String} advertiserId - advertiser id
 * @param {String} activityTagString - activity tag string
 * @param {String} groupTagString - group tag string
 */
export function trackFloodlightCounter(trackingInfo) {
  const { google } = trackingInfo;

  const gaEventData = {
    event: EVENTS_MAP.FLOODLIGHT_COUNT_EVENT,
    floodlight_activity_tag: google.activityTagString,
    floodlight_group_tag: google.groupTagString,
    floodlight_advertiser_id: google.advertiserId,
  };

  newrelicPageAction(EVENTS_MAP.FLOODLIGHT_COUNT_EVENT, gaEventData);
  gaEvent(gaEventData);
}

/**
 * @summary Track a Microsoft conversion
 * @param {String} microsoftEventAction - microsoft event action
 * @param {String} microsoftEventCategory - microsoft event category
 * @param {Number} microsoftRevenue - microsoft revenue
 */
async function trackMicrosoftConversion(trackingInfo, formValues = {}) {
  const { microsoft, revenue } = trackingInfo;

  const userInfo = await extractUserInfo(formValues);

  const gaEventData = {
    event: EVENTS_MAP.MICROSOFT_CONVERSION_EVENT,
    microsoft_event_action: microsoft.action,
    microsoft_event_category: microsoft.category,
    microsoft_event_label: microsoft.label,
    microsoft_event_value: microsoft.value,
    floodlight_revenue: revenue,
    user: userInfo,
  };

  newrelicPageAction(EVENTS_MAP.MICROSOFT_CONVERSION_EVENT, {
    ...gaEventData,
    ...getGaSessionIds(),
  });
  gaEvent(gaEventData);
  LogInfo(EVENTS_MAP.MICROSOFT_CONVERSION_EVENT, {
    description: getMicrosoftDescription(microsoft, revenue),
  });
}

/**
 * @summary Track a Bing page view
 * @param {String} uetTagId - uet tag id
 */
function trackBingPageView(trackingInfo) {
  const { microsoft } = trackingInfo;

  const gaEventData = {
    event: EVENTS_MAP.MICROSOFT_PAGE_VIEW,
    uet_tag_id: microsoft.uetTagId,
  };

  newrelicPageAction(EVENTS_MAP.MICROSOFT_PAGE_VIEW, gaEventData);
  gaEvent(gaEventData);
}

const ATTRIBUTION_DESTINATION_MAP = {
  [GOOGLE]: {
    conversion: trackFloodlightConversion,
    nonConversion: trackFloodlightCounter,
  },
  [MICROSOFT]: {
    conversion: trackMicrosoftConversion,
    nonConversion: trackBingPageView,
  },
};

/**
 * @summary Log an error if the tag info is not as expected
 * @param {String} floodlightEvent - the event to track
 * @param {Object} floodlightActivityValue - floodlight activity values
 */
function spotCheckTagInfo(floodlightEvent, floodlightActivityValue) {
  const { mapKeys } = floodlightActivityValue;
  if (
    mapKeys.adPlatformDestination === MICROSOFT &&
    mapKeys.isConversion === false &&
    floodlightEvent !== FLOODLIGHT_EVENTS.PAGE_VIEW
  ) {
    LogError(
      'Microsoft Tracking does not support Counters outside of Page Views'
    );
  }

  if (
    mapKeys.adPlatformDestination === GOOGLE &&
    mapKeys.isConversion === true &&
    !BACKEND_EVENT_TO_FLOODLIGHT_EVENT_MAP[
      EVENTS_MAP.PROFILE_PAYABLE_USER
    ].includes(mapKeys.eventType)
  ) {
    LogError('Unexpected Conversion Event Type for Google Floodlight');
  }
}

/**
 * @summary Track a floodlight event
 * @param {String} floodlightEvent - the event to track
 * @param {Object} floodlightActivityValues - floodlight activity values
 * @param {Object} floodLightActivityFilters - user session filters object
 */
// TODO: [T1-11350] Rename from trackFloodlightActivity to trackUserAttributionActivity
export function trackFloodlightActivity(
  floodlightEvent,
  floodlightActivityValues = { eventValues: [] },
  floodLightActivityFilters = {},
  metaData = {} // { revenue, adjustedRevenue, schoolCode, formValues }
) {
  const mappedEvents =
    BACKEND_EVENT_TO_FLOODLIGHT_EVENT_MAP[floodlightEvent] || [];

  // Its possible there are no events for this site
  if (!mappedEvents.length || !floodlightActivityValues.eventValues.length) {
    return;
  }

  const userAttributionFilters = floodLightActivityFilters;

  // We should be always getting these back for a user
  if (
    typeof userAttributionFilters.isClickUser !== 'boolean' ||
    typeof userAttributionFilters.campaignType !== 'string' ||
    typeof userAttributionFilters.propertyOrigin !== 'string' ||
    typeof userAttributionFilters.trackingSchoolCode !== 'string'
  ) {
    LogError('Invalid floodlight activity filters', {
      floodLightActivityFilters: userAttributionFilters,
      floodlightEvent,
    });
    return;
  }

  const floodlightEventsToFire = floodlightActivityValues.eventValues.filter(
    ({ filters, mapKeys }) => {
      return (
        filters.isClickUser === userAttributionFilters.isClickUser &&
        filters.campaignType === userAttributionFilters.campaignType &&
        filters.propertyOrigin === userAttributionFilters.propertyOrigin &&
        filters.trackingSchoolCode ===
          userAttributionFilters.trackingSchoolCode &&
        filters.adPlatformSource === userAttributionFilters.adPlatformSource &&
        mappedEvents.includes(mapKeys.eventType)
      );
    }
  );

  floodlightEventsToFire.forEach((floodlightActivityValue) => {
    const { mapKeys, google, microsoft } = floodlightActivityValue;

    spotCheckTagInfo(floodlightEvent, floodlightActivityValue);

    const trackingFunctions =
      ATTRIBUTION_DESTINATION_MAP[mapKeys.adPlatformDestination];

    if (!trackingFunctions) {
      LogError('Invalid adPlatformDestination', {
        adPlatformDestination: mapKeys.adPlatformDestination,
        floodlightEvent,
      });
      return;
    }

    const trackingFunction = mapKeys.isConversion
      ? trackingFunctions.conversion
      : trackingFunctions.nonConversion;

    let revenue;

    if (mapKeys.isConversion) {
      revenue =
        mapKeys.eventType === 'Conversion_Original_Lead_Revenue'
          ? metaData.revenue
          : metaData.adjustedRevenue;
    }

    if (
      !mapKeys.isConversion &&
      floodlightEvent === FLOODLIGHT_EVENTS.PROFILE_PAYABLE_USER
    ) {
      LogError('Floodlight Counter event fired for Profile Payable User');
    }

    trackingFunction(
      {
        microsoft,
        google,
        revenue,
      },
      metaData.formValues
    );
  });
}
