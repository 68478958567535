import get from 'lodash/get';
import superagent from 'superagent';
import { TRIADMS_BACKEND, TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import { LogInfo, LogWarning, getSessionDataForLogging } from './logging';
import configs from '../configs';
import {
  getStoredQueryParams,
  getUserSessionId,
  getPageViewId,
} from './analyticsHelpers';
import isBrowser from './isBrowser';
import { isSessionStorageEnabled, retryablePromise } from './generalUtils';
import { APIError } from './errors/APIError';

const MEMORY_CACHE = {};

/**
 *
 * @param {Object} args
 * {String} args.method - either get or post
 * {String} args.url - the endpoint you are looking to hit
 * {Object} args.body - the request body
 * {Object} args.query - url params to send
 * {Boolean} args.shouldRetry - Deprecated use with retryablePromise
 * {String} args.contentType - content type to accept
 * {Boolean} args.isWP - is this a request to our WP server, can only be done server side
 * {Boolean} args.getRawRes - if true will return the full res obj
 * {String} args.cacheKey - if set then will cache results in session storage
 * {String} args.responseTimeout - how long to wait for the server to start sending
 * {String} args.deadlineTimeout - how long to wait for the file to finish loading
 */
function request(
  {
    method = 'get',
    url,
    body,
    query,
    contentType,
    isWP = false,
    getRawRes = false,
    headers,
    cacheKey,
    useMemoryCache = false,
    responseTimeout,
    deadlineTimeout,
  },
  shouldLogFailure = true
) {
  const req = superagent[method](isWP ? `${configs.WPHost}${url}` : url);

  isWP && req.set('Host', configs.WPDomain);
  isWP && req.set('User-Agent', 'node');
  headers && req.set(headers);
  body && req.send(body);
  query && req.query(query);

  // All Api on the browser will send sessionId and params
  const isTriadRequest = isBrowser() && url.includes(TRIAD_PROXY_ROUTE);
  // TODO: [FSIU - 143]: this is not the right way to pass in global info. We need to refactor. Do not use any other info from getSessionDataForLogging
  const sessionData = getSessionDataForLogging();
  if (isTriadRequest && method === 'post') {
    req.send({
      sessionId: getUserSessionId(),
      pageViewId: getPageViewId(),
      queryParams: getStoredQueryParams().requestArray,
      schoolCode: body?.schoolCode || sessionData.schoolCode,
    });
  } else if (isTriadRequest && method === 'get') {
    req.query({
      sessionId: getUserSessionId(),
      pageViewId: getPageViewId(),
      schoolCode: query?.schoolCode || sessionData.schoolCode,
    });
  }

  // generate traceId and pass it in all requests. If on server take it from request
  const uuid = get(
    body || query,
    'requestUUID',
    `${Date.now()}_${Math.random()}`
  );

  // On Browser attached extra headers/data to request
  if (isBrowser()) {
    body && req.send({ requestUUID: uuid });
    query && req.query({ requestUUID: uuid });
  }

  if (cacheKey && isBrowser() && useMemoryCache) {
    const cachedValue = MEMORY_CACHE[cacheKey];
    if (cachedValue) {
      return Promise.resolve(JSON.parse(cachedValue));
    }
  }

  if (cacheKey && isBrowser() && isSessionStorageEnabled() && !useMemoryCache) {
    const cachedValue = sessionStorage.getItem(cacheKey);
    if (cachedValue) {
      return Promise.resolve(JSON.parse(cachedValue));
    }
  }

  // On Server attached extra headers/data to request
  if (!isBrowser()) {
    // always attach auth token to server requests
    if (url.includes(TRIADMS_BACKEND)) {
      process.env.TRIAD_API_AUTH &&
        req.set({ Authorization: process.env.TRIAD_API_AUTH });
    }
  }

  return req
    .accept(contentType || 'application/json')
    .timeout({
      response: responseTimeout || 20000, // Wait 20 seconds for the server to start sending,
      deadline: deadlineTimeout || 30000, // but allow 30 seconds minute for the file to finish loading.
    })
    .then((res) => {
      const results = getRawRes ? res : res.body;
      // base request function will only cache on browsers where a cacheKey is passed in and the response from backend has not told us to exclude from cache
      if (cacheKey && isBrowser() && res.body.Cache !== false) {
        try {
          if (useMemoryCache) {
            MEMORY_CACHE[cacheKey] = JSON.stringify({ ...results, cacheKey });
          } else if (isSessionStorageEnabled()) {
            sessionStorage.setItem(
              cacheKey,
              JSON.stringify({ ...results, cacheKey })
            );
          }
        } catch (error) {
          LogWarning(`Failed to set API results in session storage`, {
            description: error.message,
            endpoint: url,
          });
        }
      }

      return results;
    })
    .catch((err) => {
      if (err.code !== 'ABORTED' && shouldLogFailure) {
        LogWarning(`${isBrowser() ? 'Browser' : 'Server'} API call Failed`, {
          description: err.message,
          endpoint: url,
          errorMode: err.code,
        });
      } else {
        LogInfo('API request aborted', { endpoint: url });
      }

      throw new APIError(
        `API Request Failed: ${err.message}`,
        err.statusCode || err.status,
        url
      );
    });
}

export function retryableRequest(requestOptions, retryOptions = {}) {
  return retryablePromise(
    (isFinalAttempt) =>
      request(requestOptions, /* shouldLogFailure */ isFinalAttempt),
    {
      maxRetryAttempts: retryOptions.maxRetryAttempts || 3,
      waitTimeBetweenFails: retryOptions.waitTimeBetweenFails || 2000,
    }
  );
}

export default request;
